(function () {
    'use strict';

    function setupMenu() {
        const menuToggle = document.getElementById('menu-toggle');
        const closeMenu = document.getElementById('close-menu');
        const mobileMenu = document.getElementById('mobile-menu');
        const mobileOverlay = document.getElementById('mobile-menu-overlay');
        const body = document.body;

        if (!menuToggle || !closeMenu || !mobileMenu || !mobileOverlay) return;

        function toggleMenu(open) {
            if (open) {
                mobileMenu.classList.remove('translate-x-full');
                mobileOverlay.classList.remove('hidden');
                body.classList.add('overflow-hidden');
                menuToggle.setAttribute('aria-expanded', 'true');
            } else {
                mobileMenu.classList.add('translate-x-full');
                mobileOverlay.classList.add('hidden');
                body.classList.remove('overflow-hidden');
                menuToggle.setAttribute('aria-expanded', 'false');
            }
        }

        menuToggle.addEventListener('click', () => toggleMenu(true));

        closeMenu.addEventListener('click', () => toggleMenu(false));
        mobileOverlay.addEventListener('click', () => toggleMenu(false));

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                toggleMenu(false);
            }
        });
    }

    function setupSearch() {
        const form = document.getElementById("search-form");
        const searchButton = document.getElementById("search-button");
        const resetButton = document.getElementById("reset-search-btn");
        const resultCount = document.getElementById("result-count");
        const apiUrl = "/qsdata";

        if (!form || !searchButton || !resetButton || !resultCount) {
            console.error("Form, reset button, search button, or result count not found.");
            return;
        }

        function updateFormData() {
            const formData = new FormData(form);
            const queryParams = new URLSearchParams(formData).toString();

            fetch(`${apiUrl}?${queryParams}`)
                .then(response => response.json())
                .then(data => {
                    searchButton.setAttribute("data-url", data.url);
                    resultCount.textContent = data.count;

                    if (data.count === "0") return;

                    const selectedValues = {
                        idType: document.getElementById("idType").value,
                        idMake: document.getElementById("idMake").value,
                        idState: document.getElementById("idState").value,
                        priceTo: document.getElementById("priceTo").value
                    };

                    updateDropdown("idType", data.types || [], selectedValues.idType, "Types");
                    updateDropdown("idMake", data.makes || [], selectedValues.idMake, "Makes");
                    updateDropdown("idState", data.states || [], selectedValues.idState, "States");
                    updateDropdown("priceTo", data.pricesTo || [], selectedValues.priceTo, "Price to");
                })
                .catch(error => console.error("Error loading search data:", error));
        }

        function updateDropdown(selectId, options, selectedValue, defaultLabel) {
            const select = document.getElementById(selectId);
            if (!select) return;

            const firstSelected = select.options.length > 0 ? select.options[select.selectedIndex].value : "";

            select.innerHTML = `<option value="">${defaultLabel}</option>`;
            options.forEach(option => {
                console.log(option);
                const isSelected = (option.id == selectedValue || option.id == firstSelected) ? "selected" : "";
                select.innerHTML += `<option value="${option.id}" ${isSelected}>${option.name}</option>`;
            });
        }

        function resetForm() {
            form.querySelectorAll("input, select, textarea").forEach(element => {
                if (element.type === "checkbox" || element.type === "radio") {
                    element.checked = false;
                } else if (element.tagName === "SELECT") {
                    element.selectedIndex = 0;
                } else {
                    element.value = "";
                }
            });

            console.log("Search form has been reset.");
            updateFormData();
        }

        document.querySelectorAll(".filter-input").forEach(input => {
            input.addEventListener("change", updateFormData);
        });

        searchButton.addEventListener("click", function () {
            window.location.href = searchButton.getAttribute("data-url");
        });

        resetButton.addEventListener("click", function (event) {
            event.preventDefault();
            resetForm();
        });

        updateFormData();
    }

    function resizeImages() {
        document.querySelectorAll(".image-container img").forEach(img => {
            img.style.objectFit = "cover";
            img.style.width = "100%";
            img.style.height = "100%";
        });
    }

    function loadDailyOffers() {
        const dailyOffersContainer = document.getElementById("daily-offers");
        const apiUrl = "/indextrailers";

        if (!dailyOffersContainer) return;

        fetch(apiUrl)
            .then(response => response.json())
            .then(trailers => {
                dailyOffersContainer.innerHTML = "";
                trailers.forEach(trailer => {
                    const trailerElement = document.createElement("div");
                    trailerElement.className = "bg-white rounded-md shadow-md overflow-hidden border border-gray-200 flex flex-col h-full";

                    trailerElement.innerHTML = `
                    <a href="${trailer.detailUrl.url}" class="flex flex-col h-full">
                        <div class="relative w-full aspect-w-16 aspect-h-9 bg-gray-100 flex items-center justify-center overflow-hidden">
                            <div class="image-container">
                                <img src="${trailer.defaultImageUrl}" alt="${trailer.adTitle}" class="trailer-image">
                            </div>
                        </div>
                        <div class="p-3 flex flex-col flex-grow justify-between">
                            <h3 class="trailer-title text-sm font-semibold text-sky-600 hover:underline">${trailer.adTitle}</h3>
                <div class="mt-auto">
                    <p class="text-textSecondary text-xs flex items-center space-x-1 mt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                        </svg>
                        <span>${trailer.displayState ? trailer.displayState + ', ' : ''} ${trailer.displayCity || ''}</span>
                    </p>

                    <div class="bg-accent/20 text-accent text-lg font-bold py-2 px-3 rounded-lg shadow-sm flex items-center justify-center mt-2">
                        ${trailer.displayPrice ? trailer.displayPrice : 'Contact for Price'}
                    </div>
                </div>
                        </div>
                    </a>
                `;

                    dailyOffersContainer.appendChild(trailerElement);
                });

                resizeImages();
            })
            .catch(error => console.error("Error loading daily offers:", error));
    }

    function adjustSearchButton() {
        function repositionSearchButton() {
            const searchButton = document.getElementById("search-button");
            const priceToDropdown = document.getElementById("priceTo");

            if (window.innerWidth < 768) {
                priceToDropdown.insertAdjacentElement("afterend", searchButton);
            } else {
                const formGrid = document.querySelector("#search-form .grid");
                formGrid.appendChild(searchButton);
            }
        }

        repositionSearchButton();
        window.addEventListener("resize", repositionSearchButton);
    }

    const FAVORITES_KEY = 'favoriteTrailers';
    const MAX_FAVORITES = 5;

    function getFavorites() {
        return JSON.parse(localStorage.getItem(FAVORITES_KEY) || '[]');
    }

    function saveFavorites(favorites) {
        localStorage.setItem(FAVORITES_KEY, JSON.stringify(favorites));
    }

    function toggleFavorite(trailerId) {
        const idStr = String(trailerId); // Always treat IDs as strings
        let favorites = getFavorites();

        const index = favorites.indexOf(idStr);
        if (index !== -1) {
            // Remove from favorites
            favorites.splice(index, 1);
        } else {
            // Add to beginning, keep max 5
            favorites.unshift(idStr);
            if (favorites.length > MAX_FAVORITES) {
                favorites = favorites.slice(0, MAX_FAVORITES);
            }
        }

        saveFavorites(favorites);
        updateFavoriteIcons();
        renderFavoriteList();
    }

    function updateFavoriteIcons() {
        const favorites = getFavorites();
        document.querySelectorAll('.favorite-toggle').forEach(button => {
            const trailerId = button.getAttribute('data-trailer-id'); // Keep as string
            const icon = button.querySelector('.favorite-icon');
            if (!icon) return;

            if (favorites.includes(trailerId)) {
                icon.classList.add('text-red-500');
                icon.classList.remove('text-gray-400');
            } else {
                icon.classList.add('text-gray-400');
                icon.classList.remove('text-red-500');
            }
        });
    }

    function bindFavoriteButtons() {
        document.querySelectorAll('.favorite-toggle').forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const trailerId = button.getAttribute('data-trailer-id'); // Always string
                toggleFavorite(trailerId);
            });
        });
    }

    function renderFavoriteList() {
        const favorites = getFavorites();
        if (favorites.length === 0) {
            updateListDOM('<p class="text-gray-500">No favorites yet.</p>');
            return;
        }

        fetch(`/favoritetrailers?ids=${favorites.join(',')}`)
            .then(res => res.json())
            .then(trailers => {
                const html = trailers.map(t => `
                <a href="${t.detailUrl.url}" class="block hover:bg-gray-50 p-2 rounded">
                    <div class="flex items-center space-x-3">
                        <img src="${t.defaultImageUrl}" alt="${t.adTitle}" class="w-12 h-10 object-cover rounded">
                        <div>
                            <p class="text-sm font-medium text-gray-900">${t.adTitle}</p>
                            <p class="text-xs text-gray-500">${t.displayState ? t.displayState + ', ' : ''}${t.displayCity}</p>
                        </div>
                    </div>
                </a>
            `).join('');
                updateListDOM(html);
            });
    }

    function updateListDOM(html) {
        const mobileList = document.getElementById('favorites-list-mobile');
        const desktopList = document.getElementById('favorites-list-desktop');
        if (mobileList) mobileList.innerHTML = html;
        if (desktopList) desktopList.innerHTML = html;
    }

    //
    // ✅ NEW FUNCTION: setupFavoritesDropdownToggle
    //
    function setupFavoritesDropdownToggle() {
        const toggleBtn = document.getElementById('favorites-toggle');
        const dropdown = document.getElementById('favorites-dropdown');
        const wrapper = document.getElementById('favorites-wrapper');

        if (!toggleBtn || !dropdown || !wrapper) return;

        toggleBtn.addEventListener('click', (e) => {
            e.stopPropagation();
            dropdown.classList.toggle('hidden');
        });

        document.addEventListener('click', (e) => {
            if (!wrapper.contains(e.target)) {
                dropdown.classList.add('hidden');
            }
        });
    }

    function setupMobileFavoritesToggle() {
        const toggleBtn = document.getElementById('mobile-favorites-toggle');
        const listContainer = document.getElementById('favorites-list-mobile');
        const chevron = document.getElementById('mobile-favorites-chevron');

        if (!toggleBtn || !listContainer) return;

        toggleBtn.addEventListener('click', () => {
            const isHidden = listContainer.classList.contains('hidden');

            if (isHidden) {
                listContainer.classList.remove('hidden');
                if (chevron) chevron.classList.add('rotate-180');
            } else {
                listContainer.classList.add('hidden');
                if (chevron) chevron.classList.remove('rotate-180');
            }
        });
    }

    document.addEventListener("DOMContentLoaded", function () {
        setupMenu();
        setupSearch();
        loadDailyOffers();
        adjustSearchButton();
        bindFavoriteButtons();
        updateFavoriteIcons();
        renderFavoriteList();
        setupFavoritesDropdownToggle();
        setupMobileFavoritesToggle();
    });

}());
